import Header from "../../../components/Header/Index";
import Body from "../../../components/Body/Index";
import Title from "../../../components/Title/Index";
import EditForm from "../Edit/Index";

const ChangePassword = () => {
  return (
    <Body>
      <Header>
        <Title title="Alteração de senha" />
      </Header>
      <EditForm />
    </Body>
  );
};

export default ChangePassword;
