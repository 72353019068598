import Contexts from "./contexts/Contexts";
import AppRoutes from "./routes/Index";

import { useState } from "react";

const App = () => {
  const [auth, setAuth] = useState(false);
  const [dependents, setDependents] = useState([]);

  return (
    <Contexts.Provider
      value={{
        auth,
        setAuth,
        dependents,
        setDependents,
      }}
    >
      <AppRoutes />
    </Contexts.Provider>
  );
};

export default App;
