import { ButtonAppDownload, ImageLink } from "./Styles";

const ButtonDownload = ({ src, href }) => {
  return (
    <ButtonAppDownload>
      <a target="_blank" href={href} rel="noreferrer">
        <ImageLink src={src} />
      </a>
    </ButtonAppDownload>
  );
};

export default ButtonDownload;
