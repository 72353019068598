import * as yup from "yup";

export const ValidationSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório!"),
  birth_date: yup
    .string()
    .required("Campo obrigatório!")
    .test("len", "Data Inválida", (value) => {
      let validation = true;

      if (value.includes("_") || value === "") {
        validation = false;
      }

      return validation;
    }),
});
