import styled from "styled-components";

export const Loading = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  header {
    width: 100%;
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0em 2em 0em;
`;

export const ButtonInput = styled.button`
  padding: 1em;
  border-radius: 1em;
  color: #fff;
  background-color: #20daab;
  border: 0px;
  font-size: 12px;
  margin: 0em 1em;

  @media (max-width: 480px) {
    padding: 0.5em 0.3em;
    border-radius: 0.5em;
  }
`;

export const ButtonDependents = styled(ButtonInput)`
  margin: initial;
`;

export const Container = styled.div`
  padding-top: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  width: 30%;
  margin: 2em 0em;
`;

export const ButtonDownloadContainer = styled.div`
  margin: 1em 0em;
`;

export const Download = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em 3em 0em 3em;
  text-align: center;
  align-items: center;
`;
