import image from "../../images/user.png";
import appStore from "../../images/app-store.png";
import playStore from "../../images/play-store.png";

import {
  Body,
  Container,
  Loading,
  NavBar,
  ButtonInput,
  ButtonDependents,
  ImageContainer,
  Download,
  Image,
  ButtonDownloadContainer,
} from "./Styles";

import { getLoggedUser } from "../../utils/user";

import { useContext, useEffect, useState } from "react";

import Title from "../../components/Title/Index";
import ButtonDownload from "./components/ButtonDownload/Index";
import InfoUser from "./components/InfoUser/Index";
import ButtonLink from "../../components/ButtonLink/Index";
import Contexts from "../../contexts/Contexts";

import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { MdAlternateEmail } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";
import Swal from "sweetalert2";

import { getAll } from "../../utils/dependent";

const Home = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [loading, setLoading] = useState(true);

  const { setAuth, dependents, setDependents } = useContext(Contexts);

  const loggout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("auth_token");
  };

  const handleLoggout = () => {
    Swal.fire({
      title: "Deseja deslogar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      preConfirm: (value) => {
        if (value === true) {
          loggout();
          setAuth(false);
        }
      },
    });
  };

  useEffect(() => {
    (async () => {
      const { created_at, email, name } = getLoggedUser();
      const { data } = await getAll();
      const dateFormated = new Date(created_at).toLocaleString();

      setName(name);
      setEmail(email);
      setCreatedAt(
        `${dateFormated.split(" ")[0]} as ${dateFormated.split(" ")[1]}`
      );
      setDependents(data);
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <Loading>
          <ReactLoading type="spin" color={"black"} height="5em" width="5em" />
        </Loading>
      ) : (
        <Body>
          <header>
            <NavBar>
              <div>
                <Link to="/edit-dependent">
                  <ButtonInput>Registrar Dependente</ButtonInput>
                </Link>

                {dependents.length > 0 && (
                  <Link to="/dependents">
                    <ButtonDependents type="button">
                      Dependentes
                    </ButtonDependents>
                  </Link>
                )}
              </div>

              <ButtonInput type="button" onClick={handleLoggout}>
                Deslogar
              </ButtonInput>
            </NavBar>
            <section>
              <a
                style={{ textDecoration: "none" }}
                href="https://www.dindim.digital/"
              >
                <Title title="DINDIM.DIGITAL" />
              </a>
              <Title title="APRENDIZAGEM REAL" />
            </section>
          </header>
          <Container>
            <ImageContainer>
              <Image src={image} />
            </ImageContainer>

            <div>
              <InfoUser
                icon={<AiOutlineUser style={{ fontSize: "30px" }} />}
                info={name}
              />

              <InfoUser
                icon={<MdAlternateEmail style={{ fontSize: "30px" }} />}
                info={email}
              />

              <InfoUser
                icon={<HiOutlineUserGroup style={{ fontSize: "30px" }} />}
                info={`N° de dependentes: ${dependents.length}`}
              />

              <InfoUser
                icon={<BsCalendarDate style={{ fontSize: "30px" }} />}
                info={createdAt}
              />
            </div>

            <Download>
              <p>
                Baixe o App, para utilizar todas as funcionalidades da
                plataforma, em um dos links abaixo 👇
              </p>
              <ButtonDownloadContainer>
                <ButtonDownload
                  src={playStore}
                  href="https://play.google.com/store/apps/details?id=digital.dindim"
                />

                <ButtonDownload
                  src={appStore}
                  href="https://apps.apple.com/us/app/dindim-digital/id1602407778"
                />
              </ButtonDownloadContainer>
            </Download>

            <div>
              <ButtonLink
                text="Para saber como acessar o app acesse"
                href="https://www.dindim.digital/tutoriais"
                link="Tutoriais"
              />
            </div>
            <div>
              <ButtonLink
                text="Para alterar a senha basta clicar em alterar"
                to="/change-password"
                link="Alterar"
              />
            </div>
          </Container>
        </Body>
      )}
    </>
  );
};

export default Home;
