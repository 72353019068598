import {
  Container,
  LineBottom,
  Content,
  Border,
  DependentData,
  Color,
  IconContainer,
} from "./Styles";

import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const InfoDependent = ({
  name,
  id,
  birthDate,
  accessNumber,
  color,
  remove,
}) => {
  const navigate = useNavigate();

  const handleEdit = async () => {
    navigate(`/edit-dependent/${id}`);
  };

  return (
    <div>
      <hr />
      <Container>
        <Border background={color}>
          <div></div>
        </Border>

        <Content>
          <DependentData>
            <p>
              <strong>Nome: </strong>
              {name}
            </p>

            <p>
              <strong>Data de aniversário: </strong>
              {birthDate}
            </p>

            <p>
              <strong>Número de acesso: </strong>
              {accessNumber}
            </p>

            <Color background={color}>
              <strong>Cor Preferida:</strong>
              <span></span>
            </Color>
          </DependentData>

          <IconContainer>
            <button type="button" onClick={handleEdit}>
              <FiEdit style={{ color: "#20daab" }} />
            </button>

            <button>
              <FiTrash2
                type="button"
                onClick={() => {
                  remove(id);
                }}
                style={{ color: "red" }}
              />
            </button>
          </IconContainer>
        </Content>
        <Border background={color}>
          <div></div>
        </Border>
      </Container>
      <LineBottom />
    </div>
  );
};

export default InfoDependent;
