import Header from "../../../components/Header/Index";
import Title from "../../../components/Title/Index";
import Body from "../../../components/Body/Index";
import EditForm from "../Edit/Index";

const Login = () => {
  return (
    <>
      <Body>
        <Header>
          <Title title="Login" />
        </Header>
        <EditForm />
      </Body>
    </>
  );
};

export default Login;
