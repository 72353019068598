import { useEffect, useContext, useState } from "react";

import Title from "../../components/Title/Index";
import InfoDependent from "./components/InfoDependent/Index";
import ButtonLink from "../../components/ButtonLink/Index";
import BackButton from "../../components/BackButton/Index";

import { getAll, remove } from "../../utils/dependent";

import { Body, Content, Loading } from "./Styles";

import { useAutoAnimate } from "@formkit/auto-animate/react";
import ReactLoading from "react-loading";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Contexts from "../../contexts/Contexts";
import { dataBaseToFrontFormatted } from "../../utils/data";

const Dependents = () => {
  const [parent] = useAutoAnimate();
  const [loading, setLoading] = useState(true);
  const { dependents, setDependents } = useContext(Contexts);
  const navigate = useNavigate();

  const handleDependents = async () => {};

  useEffect(() => {
    (async () => {
      const { data } = await getAll();
      let dependentFormated;

      data.forEach((element) => {
        const birthDate = dataBaseToFrontFormatted(element.birth_date);

        if (dependentFormated) {
          dependentFormated = [
            ...dependentFormated,
            { ...element, birth_date: birthDate },
          ];
        } else {
          dependentFormated = [{ ...element, birth_date: birthDate }];
        }
      });

      if (dependentFormated) {
        return setDependents(dependentFormated);
      } else {
        return navigate("/");
      }
    })();

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const handleRemove = async (id) => {
    const { value } = await Swal.fire({
      title: "Deseja remover este dependente?",
      icon: "question",
      iconHtml: "?",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      showCloseButton: true,

      preConfirm: (value) => {
        return value;
      },
    });

    if (value === true && dependents.length === 1) {
      await remove(id);

      navigate("/");
    } else if (value === true) {
      await remove(id);

      handleDependents();
    }
  };

  return (
    <>
      {loading ? (
        <Loading>
          <ReactLoading type="spin" color={"black"} height="5em" width="5em" />
        </Loading>
      ) : (
        <Body>
          <header>
            <Title title="Dependentes" />
          </header>

          <Content ref={parent}>
            <div>
              {dependents.map((element) => {
                return (
                  <InfoDependent
                    name={element.name}
                    birthDate={element.birth_date}
                    accessNumber={element.access_number}
                    color={element.label}
                    id={element.id}
                    remove={handleRemove}
                  />
                );
              })}
            </div>

            <div>
              <BackButton to="/" />

              <ButtonLink
                text="Saiba como usar o número de acesso"
                href="https://www.dindim.digital/tutoriais-dependentes"
                link="Tutorial"
              />
            </div>
          </Content>
        </Body>
      )}
    </>
  );
};

export default Dependents;
