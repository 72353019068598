import styled from "styled-components";

export const Container = styled.div`
  margin-top: 15px;
`;

export const StyledInput = styled.input`
  //font-family: HV Pinocchio Bold;
  background-color: #fff;
  color: black;
  width: 100%;
  padding: 1em;
  font-size: 16px;
  border-radius: 1em;
  outline: 0px;
  border: 2px solid #e6e6e8;
`;

export const Error = styled.p`
  color: #ff5b4d;
  width: 80%;
  font-size: 0.6rem;
  padding: 0.5rem 0rem;
`;
