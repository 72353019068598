import styled from "styled-components";

export const Text = styled.p`
  font-size: 18px;
  padding: 1em 0em;

  @media (max-width: 509px) {
    font-size: 12px;
  }
`;

export const Button = styled.button`
  color: #fff;
  margin-left: 1em;
  background-color: #20daab;
  border-radius: 1em;
  padding: 0.5em 1em;
  border: 0px;
  font-size: 18px;
  cursor: grab;

  @media (max-width: 490px) {
    margin-left: 0.5em;
    padding: 0.7em;
  }

  @media (max-width: 509px) {
    font-size: 14px;
  }
`;
