import { useParams } from "react-router-dom";
import Header from "../../../components/Header/Index";
import Body from "../../../components/Body/Index";
import Title from "../../../components/Title/Index";
import EditForm from "../Edit/Index";

const EditDependent = () => {
  const params = useParams();

  return (
    <Body>
      <Header>
        <Title title={params.id ? "Editando" : "Registrar Dependente"} />
      </Header>

      <EditForm />
    </Body>
  );
};

export default EditDependent;
