import { Link } from "react-router-dom";

import { Button } from "./Styles";

const BackButton = ({ to }) => {
  return (
    <Link to={to}>
      <Button>Voltar</Button>
    </Link>
  );
};

export default BackButton;
