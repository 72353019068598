import api from "../services/api";

export const register = async (body) => {
  const response = await api.post("/users", { user: body }).catch((error) => {
    if (error) {
      return error;
    }
  });

  return response;
};

export const login = async (body) => {
  const response = await api.post("/auth", body).catch((error) => {
    if (error) {
      return error;
    }
  });
  return response;
};

export const changePassword = async (body) => {
  if (localStorage.getItem("tokenEditPassword")) {
    const response = await api
      .put(
        "/auth/me",
        { user: body },
        {
          headers: { Authorization: localStorage.getItem("tokenEditPassword") },
        }
      )
      .catch((error) => {
        if (error) {
          return error;
        }
      });

    localStorage.removeItem("tokenEditPassword");

    return response;
  } else {
    const response = await api
      .put(
        "/auth/me",
        { user: body },
        {
          headers: { Authorization: localStorage.getItem("auth_token") },
        }
      )
      .catch((error) => {
        if (error) {
          return error;
        }
      });

    return response;
  }
};

export const loggedUser = (body) => {
  localStorage.setItem("user", JSON.stringify(body));
};

export const getLoggedUser = () => {
  if (localStorage.getItem("user")) {
    return JSON.parse(localStorage.getItem("user"));
  }
};

export const token = (token) => {
  localStorage.setItem("auth_token", token);
};

export const recoverPassword = async (body) => {
  const response = await api.post("/users/password_recover", body);

  return response;
};
