import { ButtonInput } from "./Styles";
import ReactLoading from "react-loading";

const Button = ({ value, loading }) => {
  return (
    <>
      {loading ? (
        <ButtonInput type="submit">
          <ReactLoading
            type="spin"
            color={"#fff"}
            height={"1em"}
            width={"1em"}
          />
        </ButtonInput>
      ) : (
        <ButtonInput type="submit">
          <div>{value}</div>
        </ButtonInput>
      )}
    </>
  );
};

export default Button;
