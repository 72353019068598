import { PasswordVisible } from "./Styles";

export const Visible = ({ set, value }) => {
  return (
    <PasswordVisible>
      <input
        type="checkbox"
        name="visibility"
        id="visibility"
        onClick={() => {
          set(!value);
        }}
      />
      <label for="visibility">
        {value ? "Ocultar senha" : "Mostrar senha"}
      </label>
    </PasswordVisible>
  );
};
