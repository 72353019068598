import { useContext, useEffect, useState } from "react";

import InputRHF from "../../../components/InputRHF/Index";
import Button from "../../../components/Button/Index";
import ButtonLink from "../../../components/ButtonLink/Index";

import Swal from "sweetalert2";

import { loggedUser, register, token } from "../../../utils/user";

import { CheckBox } from "./Styles";
import Contexts from "../../../contexts/Contexts";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "./validationForm";
import { Visible } from "../../../components/Visible/Index";
import FormStyle from "../../../components/FormStyle/Index";

const swalModal = (text) => {
  if (text) {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: text,
    });
  } else {
    Swal.fire("Sua conta foi criada com sucesso!", "Bom Trabalho", "success");
  }
};

const EditForm = () => {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setAuth } = useContext(Contexts);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  const handleRegister = async (userCreate) => {
    if (loading === false) {
      setLoading(true);

      if (confirm === false) {
        swalModal("Aceite os termos para poder prosseguir!");
      } else {
        const { status, data } = await register(userCreate);

        if (status === 201 || status === 200) {
          await swalModal();

          loggedUser(data);
          token(data.auth_token);
          setAuth(true);

          navigate("/");
        } else {
          await swalModal("Ja existe uma conta com esse email!");
          setLoading(false);
          reset({ email: "" });
        }
      }

      setLoading(false);
    }
  };

  const handleTerms = () => {
    if (confirm === false) {
      Swal.fire({
        title:
          "Estou ciente de que o Dindim.Digital é apenas uma plataforma para auxiliar na educação financeira infantil. Não trabalhamos com nenhuma transação e não temos ligação nenhuma com os valores informados no aplicativo.",
        icon: "",
        iconHtml: "",
        confirmButtonText: "Aceito",
        cancelButtonText: "Não aceito",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        showCloseButton: true,

        preConfirm: (value) => {
          if (value === true) {
            setConfirm(true);
          }
        },
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      navigate("/");
    }
  });

  return (
    <FormStyle>
      <form onSubmit={handleSubmit(handleRegister)}>
        <InputRHF
          placeholder="Nome completo"
          type="text"
          name="name"
          control={control}
          error={errors.name?.message}
        />

        <InputRHF
          placeholder="Email"
          type="email"
          name="email"
          control={control}
          error={errors.email?.message}
        />

        <InputRHF
          placeholder="Senha"
          type={passwordVisible ? "text" : "password"}
          name="password"
          control={control}
          error={errors.password?.message}
        />

        <InputRHF
          placeholder="Repita a senha"
          type={passwordVisible ? "text" : "password"}
          name="password_confirmation"
          control={control}
          error={errors.password_confirmation?.message}
        />

        <Visible set={setPasswordVisible} value={passwordVisible} />

        <Button loading={loading} value="cadastrar" action={handleRegister} />

        <CheckBox>
          <input onClick={handleTerms} type="checkbox" checked={confirm} />
          <label>
            concordo com os
            <span onClick={handleTerms}>termos de uso</span>
          </label>
        </CheckBox>

        <ButtonLink text="Já tem uma conta?" to="/" link="Logar" />
      </form>
    </FormStyle>
  );
};

export default EditForm;
