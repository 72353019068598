import Body from "../../../components/Body/Index";
import Header from "../../../components/Header/Index";
import Title from "../../../components/Title/Index";
import EditForm from "../Edit/Index";

const RecoverPassword = () => {
  return (
    <Body>
      <Header>
        <Title title="Recuperação de senha" />
      </Header>

      <EditForm />
    </Body>
  );
};

export default RecoverPassword;
