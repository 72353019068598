import * as yup from "yup";

export const ValidationSchema = yup.object({
  name: yup.string().required("Campo obrigatório!"),
  email: yup
    .string()
    .email("Informe um email válido")
    .required("Campo obrigatório!"),
  password: yup
    .string()
    .min(4, "a Senha deve ter no mínimo 4 caracteres!")
    .required("Campo obrigatório!"),
  password_confirmation: yup
    .string()
    .min(4, "a Senha deve ter no mínimo 4 caracteres!")
    .oneOf([yup.ref("password")], "As senhas não são iguais")
    .required("Campo obrigatório!"),
});
