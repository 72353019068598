import { Text, Button } from "./Styles";

import { Link } from "react-router-dom";

const ButtonLink = ({ text, to, link, href }) => {
  return (
    <div>
      <Text>
        {text}
        {to ? (
          <Link to={to}>
            <Button type="button"> {link}</Button>
          </Link>
        ) : (
          <a href={href}>
            <Button target="_blank" type="button">
              {link}
            </Button>
          </a>
        )}
      </Text>
    </div>
  );
};

export default ButtonLink;
