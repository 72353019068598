import { useEffect, useState } from "react";

import { Gender, Colors, Color } from "./Styles";

import { edit, getAll, register } from "../../../utils/dependent";
import { dataBaseToFrontFormatted } from "../../../utils/data";

import { useNavigate, useParams } from "react-router-dom";

import InputRHF from "../../../components/InputRHF/Index";
import Button from "../../../components/Button/Index";
import BackButton from "../../../components/BackButton/Index";

import Swal from "sweetalert2";

import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema } from "./validationForm";
import { useForm } from "react-hook-form";
import InputMaskRHF from "../../../components/InputMaskRHF/Index";
import FormStyle from "../../../components/FormStyle/Index";

const swalModal = (text, edited) => {
  if (text) {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: text,
    });
  } else {
    Swal.fire(
      edited ? edited : "Dependente registrado com sucesso!",
      "Bom Trabalho",
      "success"
    );
  }
};

const EditForm = () => {
  const [loading, setLoading] = useState(false);
  const [genderSelected, setGenderSelected] = useState("male");
  const [colorSelected, setColorSelected] = useState("#23D9A5");
  const gender = ["male", "female"];
  const colors = [
    "#23D9A5",
    "#F2D022",
    "#BF0B3B",
    "#0F60D6",
    "#9543ED",
    "#D9328E",
    "#4F515E",
  ];
  const navigate = useNavigate();
  const params = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  const handleRegister = async (data) => {
    if (loading === false) {
      setLoading(true);
      const dependentEdit = {
        ...data,
        gender: genderSelected,
        label: colorSelected,
      };

      if (params.id) {
        const { status } = await edit(dependentEdit, params.id);

        if (status === 201 || status === 200) {
          await swalModal("", "Dependente editado com sucesso");

          navigate("/dependents");
        }
      } else {
        const { status } = await register(dependentEdit);

        if (status === 201 || status === 200) {
          swalModal();

          navigate("/dependents");
        } else {
          swalModal(`${data?.name} ja foi cadastrado anteriormente`);
        }
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      (async () => {
        const { data } = await getAll();
        let dependentEdit = data.find((element) => {
          return element.id === params.id;
        });

        dependentEdit = {
          ...dependentEdit,
          birth_date: dataBaseToFrontFormatted(dependentEdit.birth_date),
        };

        reset({
          name: dependentEdit?.name,
          birth_date: dependentEdit?.birth_date,
          label: dependentEdit?.label,
          gender: dependentEdit?.gender,
        });

        setColorSelected(dependentEdit?.label);
        setGenderSelected(dependentEdit?.gender);
      })();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <FormStyle>
      <form onSubmit={handleSubmit(handleRegister)}>
        <InputRHF
          placeholder="Nome do dependente"
          type="text"
          name="name"
          control={control}
          error={errors.name?.message}
        />

        <InputMaskRHF
          mask="99/99/9999"
          type="text"
          control={control}
          name="birth_date"
          placeholder="Data de nascimento"
          error={errors.birth_date?.message}
        />

        <Gender>
          {gender &&
            gender.map((element) => {
              return (
                <button
                  type="button"
                  onClick={() => {
                    setGenderSelected(element);
                  }}
                  style={{
                    backgroundColor:
                      element === genderSelected ? "#BCF5E6" : "#fff",
                    color: element === genderSelected ? "#075B44" : "black",
                  }}
                >
                  {element === "male" ? "Menino" : "Menina"}
                </button>
              );
            })}
        </Gender>
        <div>
          <strong>
            <p style={{ textAlign: "center" }}>Cor favorita</p>
          </strong>
        </div>
        <Colors>
          {colors &&
            colors.map((color) => {
              return (
                <Color
                  type="button"
                  onClick={() => {
                    setColorSelected(color);
                  }}
                  style={{
                    backgroundColor: color,
                    border:
                      colorSelected === color
                        ? "solid 2px black"
                        : "solid 2px #fff",
                  }}
                ></Color>
              );
            })}
        </Colors>
        <div>
          <Button loading={loading} value={params.id ? "Editar" : "Criar"} />
        </div>
        <BackButton to={params.id ? "/dependents" : "/"} />
      </form>
    </FormStyle>
  );
};

export default EditForm;
