import { useEffect, useState } from "react";

import Button from "../../../components/Button/Index";
import InputRHF from "../../../components/InputRHF/Index";
import BackButton from "../../../components/BackButton/Index";

import Swal from "sweetalert2";
import { getLoggedUser, changePassword } from "../../../utils/user";

import { useNavigate, useLocation } from "react-router-dom";
import { ValidationSchema } from "./validationForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Visible } from "../../../components/Visible/Index";
import FormStyle from "../../../components/FormStyle/Index";

const EditForm = () => {
  const [loading, setLoading] = useState(false);
  const [logged, setLogged] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  const handleRecover = async (data) => {
    if (loading === false) {
      setLoading(true);

      try {
        await changePassword(data);

        Swal.fire(`Senha alterada com sucesso!`, "", "success");
        navigate("/");
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu algum erro ao tentar alterar a senha. Tente novamente",
        });
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    const user = getLoggedUser();

    if (search) {
      (async () => {
        const authToken = search.substring(7);

        await localStorage.setItem("tokenEditPassword", authToken);
      })();
    }

    if (user) {
      setLogged(true);
    } else {
      navigate("/");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <FormStyle>
      <form onSubmit={handleSubmit(handleRecover)}>
        <InputRHF
          placeholder="Senha"
          type={passwordVisible ? "text" : "password"}
          name="password"
          control={control}
          error={errors.password?.message}
        />

        <InputRHF
          placeholder="Repita a senha"
          type={passwordVisible ? "text" : "password"}
          name="password_confirmation"
          control={control}
          error={errors.password_confirmation?.message}
        />

        <Visible set={setPasswordVisible} value={passwordVisible} />

        <Button loading={loading} value="Alterar senha" />

        <BackButton to={logged ? "/" : "/recover-password"} />
      </form>
    </FormStyle>
  );
};

export default EditForm;
