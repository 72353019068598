import { Controller } from "react-hook-form";
import { Container, StyledInput, Error } from "./Styles";

const InputRHF = ({ name, control, placeholder, type, error, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Container>
          <div>
            <StyledInput
              type={type}
              placeholder={placeholder}
              {...rest}
              {...field}
            />
          </div>
          {error && (
            <div>
              <Error>{error}</Error>
            </div>
          )}
        </Container>
      )}
    />
  );
};
export default InputRHF;
