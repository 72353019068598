import Title from "../../../components/Title/Index";
import Body from "../../../components/Body/Index";
import EditForm from "../Edit/Index";
import Header from "../../../components/Header/Index";

const Register = () => {
  return (
    <>
      <Body>
        <Header>
          <Title title="Cadastre-se" />
        </Header>
        <EditForm />
      </Body>
    </>
  );
};

export default Register;
