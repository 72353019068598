import { DataItem, Text } from "./Styles";

const InfoUser = ({ info, icon }) => {
  return (
    <DataItem>
      {icon}
      <strong>
        <Text>{info}</Text>
      </strong>
    </DataItem>
  );
};

export default InfoUser;
