import { HeaderContent } from "./Styles";

const Header = ({ children }) => {
  return (
    <header>
      <HeaderContent>{children}</HeaderContent>
    </header>
  );
};

export default Header;
