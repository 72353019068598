import { useState } from "react";

import Button from "../../../components/Button/Index";
import InputRHF from "../../../components/InputRHF/Index";
import BackButton from "../../../components/BackButton/Index";

import Swal from "sweetalert2";

import { recoverPassword } from "../../../utils/user";

import { ValidationSchema } from "./validationForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Error } from "../../../components/InputRHF/Styles";
import FormStyle from "../../../components/FormStyle/Index";

const EditForm = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  const handleRecover = async (data) => {
    if (loading === false) {
      setLoading(true);

      try {
        await recoverPassword(data);

        Swal.fire(
          "vai receber um email de redefinição de senha em alguns instantes! Fique de olho no email",
          " ",
          "success"
        );
      } catch (error) {
        setStatus("O email não existe!");

        setTimeout(() => {
          setStatus("");
        }, 1000 * 3);
      }

      setLoading(false);
    }
  };

  return (
    <FormStyle>
      <form onSubmit={handleSubmit(handleRecover)}>
        <div>
          <InputRHF
            type="email"
            placeholder="Email"
            name="email"
            control={control}
            error={errors.email?.message}
          />

          {status && <Error>{status}</Error>}
        </div>

        <div>
          <Button loading={loading} value="Recuperar" />
        </div>

        <div>
          <BackButton to="/" />
        </div>
      </form>
    </FormStyle>
  );
};

export default EditForm;
