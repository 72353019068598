import styled from "styled-components";

export const CheckBox = styled.div`
  padding: 1em 0em 2em 0em;
  display: flex;
  align-items: center;

  label {
    padding-left: 0.2em;
  }

  span {
    padding-left: 0.2em;
    color: #20daab;
    text-decoration: underline;
    cursor: pointer;
  }
`;
