import styled from "styled-components";

export const ButtonInput = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1em;
  font-size: 16px;
  color: #fff;
  border-radius: 1em;
  border: 0px;
  background-color: #20daab;
  margin-top: 1em;

  cursor: grab;

  div {
    height: 1em;
  }
`;
