import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    background-color: #fff;
  }
`;

export const AppName = styled.div`
  h1 {
    padding: 1em 0em;
  }

  @media (max-width: 600px) {
    h1 {
      padding-bottom: initial;
    }
  }
`;

export const MainContent = styled.div`
  background-color: #fff;
  width: 50%;
  height: 100%;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
  overflow: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1000px) {
    width: 90%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    border-radius: initial;
    box-shadow: initial;
  }
`;
