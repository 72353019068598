import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

import { Container, Error } from "./Styles";

const InputMaskRHF = ({
  name,
  control,
  placeholder,
  mask,
  type,
  error,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Container>
          <div>
            <InputMask
              mask={mask}
              type={type}
              placeholder={placeholder}
              onChange={field.onChange}
              value={field.value}
              {...rest}
            />
          </div>
          {error && (
            <div>
              <Error>{error}</Error>
            </div>
          )}
        </Container>
      )}
    />
  );
};

export default InputMaskRHF;
