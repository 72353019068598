import Header from "../Title/Index";
import { AppName, Container,  MainContent } from "./Styles";

const Body = ({ children }) => {
  return (
    <Container>
      <AppName>
        <Header title="Dindim.Digital" />
      </AppName>

      <MainContent>{children}</MainContent>
    </Container>
  );
};

export default Body;
