import { useState, useContext } from "react";
import Contexts from "../../../contexts/Contexts";

import InputRHF from "../../../components/InputRHF/Index";
import Button from "../../../components/Button/Index";
import ButtonLink from "../../../components/ButtonLink/Index";

import { Recover } from "./Styles";

import { login, loggedUser, token } from "../../../utils/user";

import { Link } from "react-router-dom";
import { ValidationSchema } from "./validationForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Error } from "../../../components/InputRHF/Styles";
import { Visible } from "../../../components/Visible/Index";
import FormStyle from "../../../components/FormStyle/Index";

const EditForm = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setAuth } = useContext(Contexts);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidationSchema),
  });

  const handleLogin = async (user) => {
    if (loading === false) {
      setLoading(true);

      const { data, status } = await login(user);

      if (status === 200) {
        setLoading(false);
        loggedUser(data.user);
        token(data.auth_token);
        setAuth(true);
      } else {
        setStatus("Email ou senha incorreto!");

        setTimeout(() => {
          setStatus("");
        }, 1000 * 3);

        setLoading(false);
      }
    }
  };

  return (
    <FormStyle>
      <form onSubmit={handleSubmit(handleLogin)}>
        <InputRHF
          placeholder={"Email"}
          type="email"
          name="email"
          control={control}
          error={errors.email?.message}
        />
        <InputRHF
          placeholder="Senha"
          type={passwordVisible ? "text" : "password"}
          name="password"
          control={control}
          error={errors.password?.message}
        />

        {status && <Error>{status}</Error>}

        <Visible set={setPasswordVisible} value={passwordVisible} />

        <Button loading={loading} value="Logar" />

        <Recover>
          <Link to="/recover-password">
            Esqueceu a <strong>senha?</strong>
          </Link>
        </Recover>

        <ButtonLink
          text="Ainda não tem conta?"
          to="/register"
          link="Criar Conta"
        />
      </form>
    </FormStyle>
  );
};

export default EditForm;
