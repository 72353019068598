import styled from "styled-components";

export const Button = styled.button`
  margin-bottom: 1em;
  color: #fff;
  background-color: #20daab;
  border-radius: 1em;
  padding: 0.5em 1em;
  border: 0px;
  font-size: 18px;
  margin-top: 2em;
  cursor: grab;

  @media (max-width: 490px) {
    padding: 1em;
  }

  @media (max-width: 509px) {
    font-size: 14px;
  }
`;
