import api from "../services/api";

export const register = async (body) => {
  const response = await api
    .post(
      "/auth/dependents",
      { dependent: body },
      {
        headers: {
          authorization: localStorage.getItem("auth_token"),
        },
      }
    )
    .catch((error) => {
      if (error) {
        return error;
      }
    });

  return response;
};

export const getAll = async () => {
  const response = await api.get("/auth/dependents", {
    headers: {
      authorization: localStorage.getItem("auth_token"),
    },
  });

  return response;
};

export const edit = async (body, id) => {
  const response = await api.put(
    `/auth/dependents/${id}`,
    { dependent: body },
    {
      headers: {
        authorization: localStorage.getItem("auth_token"),
      },
    }
  );

  return response;
};

export const remove = async (id) => {
  const response = await api.delete(`/auth/dependents/${id}`, {
    headers: {
      authorization: localStorage.getItem("auth_token"),
    },
  });

  return response;
};
