import styled from "styled-components";

export const Recover = styled.div`
  padding: 1em 0em 2em 0em;

  a {
    text-decoration: none;
    color: black;
  }
`;
