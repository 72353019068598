import { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Contexts from "../contexts/Contexts";
import Home from "../pages/Home/Index";
import Login from "../pages/Login/Index/Index";
import ChangePassword from "../pages/ChangePassword/Index/Index";
import Register from "../pages/Register/Index/Index";
import RecoverPassword from "../pages/RecoverPassword/Index/Index";
import EditDependent from "../pages/EditDependent/Index/Index";
import Dependents from "../pages/Dependents/Index";

const AppRoutes = () => {
  const { auth, setAuth } = useContext(Contexts);

  useEffect(() => {
    if (localStorage.getItem("auth_token")) {
      setAuth(true);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      {auth ? (
        <Routes>
          <Route path="/" element={<Home />} />
          {localStorage.getItem("auth_token") && (
            <Route path="/change-password" element={<ChangePassword />} />
          )}
          <Route path="/edit-dependent" element={<EditDependent />} />
          <Route path="/edit-dependent/:id" element={<EditDependent />} />
          <Route path="/dependents" element={<Dependents />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/recover-password" element={<RecoverPassword />} />

          <Route path="/change-password/*" element={<ChangePassword />} />
        </Routes>
      )}
    </Router>
  );
};

export default AppRoutes;
