import styled from "styled-components";

export const ButtonAppDownload = styled.button`
  background: none;
  border: none;
`;

export const ImageLink = styled.img`
  display: block;
  width: 10em;
`;
