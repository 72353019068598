import styled from "styled-components";

export const PasswordVisible = styled.div`
  padding-top: 1em;
  display: flex;
  align-items: center;

  input {
    margin-right: 0.5em;
  }
`;
