import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 8em;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
`;

export const LineBottom = styled.hr`
  margin-bottom: 1em;
`;

export const Border = styled.div`
  display: flex;

  div:first-child {
    width: 1em;
    background-color: ${({ background }) => background};
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DependentData = styled.div`
  padding: 0.5em 0em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    padding-left: 0.5em;
  }
`;

export const Color = styled.p`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5em;
    padding: 1em;
    border-radius: 1em;
    background-color: ${({ background }) => background};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.5em;

  button {
    font-size: 20px;
    border-radius: 0.5em;
    background: none;
    border: 0px;
    padding: 0.5em;
    transition-duration: 0.5s;
  }

  button:hover {
    background-color: #f2f2f2;
  }
`;
