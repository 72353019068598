import styled from "styled-components";

export const DataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em 0em;
`;

export const Text = styled.p`
  padding-left: 1em;
`;
