import styled from "styled-components";

export const Select = styled.select`
  //font-family: HV Pinocchio Bold;
  color: black;
  width: 100%;
  padding: 1em;
  font-size: 16px;
  border-radius: 1em;
  outline: 0px;
  border: 2px solid #e6e6e8;
  margin-bottom: 1em;
  background-color: #fff;
`;

export const Gender = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;

  button {
    //font-family: HV Pinocchio Bold;
    color: black;
    width: 100%;
    padding: 1em;
    font-size: 16px;
    border-radius: 1em;
    outline: 0px;
    border: 2px solid #e6e6e8;
  }

  button:first-child {
    margin-right: 1em;
  }
`;

export const Colors = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0em;

  @media (max-width: 308px) {
    flex-wrap: wrap;
  }
`;

export const Color = styled.button`
  padding: 1.5em;
  border-radius: 2em;
  background: none;
  border: 0px;
  margin-right: 0.3em;

  :hover {
    border: solid 2px black;
  }

  @media (max-width: 600px) {
    padding: 1.2em;
  }
`;
